import React, { useState } from 'react';
import imagee from "./menu-icon.png"
import "./NavIcon.css"

const NavbarIcon =  () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const links = [
    { url: '/', text: 'Home' },
    { url: '/about', text: 'Our Team' },
    { url: '/products', text: 'Products' },
    { url: '/', text: 'Testimonials' }
    // Add more links as needed
  ];

  return (
    <div className="navbar-icon">
      <img src={imagee} alt="Menu" onClick={toggleMenu} />
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.url}>{link.text}</a>
            </li>
          ))}
        </ul>
      </div>
      {isOpen && <div className="backdrop" onClick={toggleMenu}></div>}
    </div>
  );
};

export default NavbarIcon;
