import React from 'react'
import p4 from"./p4.jpg"
import "./Testimonials.css"
import Footer from './Footer'
function Testimonials() {
  return (
    <>
    <div className='container1'>
        <h2>Testimonials</h2>
        <section className="testimonials">
        <div className="testimonial">
        <div className='ct'>
             <img className='testimg' src={p4}></img>
          </div>
          <p>I can't thank Tremonte Goaltending enough for the impact they've had on my hockey career. Anthony's guidance and mentorship have been instrumental in my development as a goaltender. Training here has not only improved my skills but also instilled confidence in my abilities on the ice. </p>
        <p>-Matt </p>
       </div>
        <div className="testimonial">
        
          <div className='ct'>
             <img className='testimg' src={p4}></img>
          </div>
         
          <p> Anthony's passion for goaltending is infectious, and it reflects in his coaching. My time at Tremonte Goaltending has been invaluable; the personalized attention and focus on fundamentals have helped me grow as a goaltender and as a person. Thank you for everything! </p>
        <p>- Robert </p>
        </div>
        {/* <div className="testimonial">
          <p>Testimonial 3</p>
        </div> */}
      </section>

      {/* Call to Action */}
     
     
    </div> 
    <Footer/>
    </>
  )
}

export default Testimonials