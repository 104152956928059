// ProductPage.js
// ProductPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Footer from './Footer';
import './ProductPage.css';
import p1 from './lob.png'; // Import product images
import p2 from './lob.png'; 
import p3 from './lob.png'; 
import p4 from './lob.png'; 
import map from "./maps.jpg"
import mapps from "./mapps.jpg"
import masp from "./mappss.jpg"
import im from "./prod.png"
import ContactForm from './ContactForm';
function ProductPage() {
  // Define products array with product details
  const products = [
    {name:"1 Private Session",
      price: "$160",
      description: "Elevate your game with personalized one-on-one training sessions tailored to your specific needs. Our experienced coaches provide focused attention and customized drills to help you sharpen your skills and achieve your goaltending goals.",
      reviews: "",
      image:p1
    },
    {
      name: "10 Pack Private Bundle",
      price: "$1500",
      description: "Commit to your improvement with our 10-session private bundle. Receive personalized coaching and targeted feedback in each session, designed to accelerate your progress and maximize your potential between the pipes.",
      reviews: "",
      image: p2
    },
    {
      name: "1 Semi-Private Session",
      price: "$90",
      description: "Train alongside another goaltender in our semi-private sessions, where small groups allow for increased competition and collaboration. Benefit from personalized guidance and targeted drills while enjoying the camaraderie and motivation of a training partner.",
      reviews: ""
      ,image:p3
    },
    {
      name: "10 Pack Semi-Private",
      price: "$800",
      description: "Partner up and push each other to new heights with our 10-session semi-private bundle. Receive expert coaching in a small-group setting, focusing on refining your technique and elevating your performance together.",
      reviews: "",
      image:p4
    }
  ];

  return (
    <div className='back'>
    <div className='top'>  <img src={im} style={{width:"100%", height:'auto', }}/>
      <div className="product-page">
     
        {/* Map through the products array and render product cards */}
        {products.map((product, index) => (
          <div className="product" key={index}>
            <h3>{product.name}</h3>
            <div className='inlin'>
              <div className='divgo'>
                <img src={product.image} alt={product.name} className="product-image" />
              </div>
              <div>
                <p style={{textAlign:"left"}}>Price: {product.price}</p>
                <p style={{textAlign:"left"}}>{product.description}</p>
              </div>
            </div>
           
          </div>
        ))}
        <div style={{padding:"5px", paddingBottom:"25px"}}>
          <p style={{textAlign:"center"}}>*Prices are subject to HST*</p>
        </div>
        <h2>Locations</h2>
        <div className="map-location" onClick={()=>{
          window.open("https://www.google.com/maps/place/Meridian+Community+Centre/@43.0476423,-79.2767728,16.25z/data=!4m7!3m6!1s0x89d34948a7fd6b81:0x5b3ccfd539250338!4b1!8m2!3d43.0473921!4d-79.2728628!16s%2Fg%2F11ddxb13n9?entry=ttu")
        }} style={{marginBottom:"55px"}}>
      <div className="map-image">
        <img src={map} alt="Map Location" />
      </div>
      <div className="location-details">
        <h3>Meridian Community Centre, Pelham</h3>
        <p>100 Meridian Wy, Fonthill, ON L0S 1E6</p>
      </div>
    </div>
    <div className="map-location" onClick={()=>{
          window.open("https://www.google.com/maps/place/Gale+Centre+Arena/@43.1136932,-79.0829814,17z/data=!3m1!4b1!4m6!3m5!1s0x89d343335dd266eb:0x24fde19a0567a837!8m2!3d43.1136893!4d-79.0804065!16s%2Fg%2F11clgtrftg?entry=ttu")
        }} style={{marginBottom:"55px"}}>
      <div className="map-image">
        <img src={masp} alt="Map Location" />
      </div>
      <div className="location-details">
        <h3>Gale Centre, Niagara Falls</h3>
        <p>5152 Thorold Stone Rd, Niagara Falls, ON L2E 0A2</p>
      </div>
    </div>
    <div className="map-location" onClick={()=>{
          window.open("https://www.google.com/maps/place/2350+Gainer+St,+Allanburg,+ON+L0S+1A0/@43.0748402,-79.2071026,17z/data=!4m6!3m5!1s0x89d34f6408bb3b8b:0x615a6a41a23eb15d!8m2!3d43.0748951!4d-79.2057615!16s%2Fg%2F11kd9__kxw?entry=ttu")
        }} style={{marginBottom:"55px"}}>
      <div className="map-image">
        <img src={mapps} alt="Map Location" />
      </div>
      <div className="location-details">
        <h3>Agrette Training Centre, Thorold</h3>
        <p>2350 Gainer St, Allanburg, ON L0S 1A0</p>
      </div>
    </div>
    
        <ContactForm style={{paddingBottom:"20px"}}/>
        <p/><p/>
      </div>
      
    </div>
    <Footer className="test" />
    </div>
  );
}

export default ProductPage;
