// AboutPage.js
import React, { useEffect, useState } from 'react';
import './AboutPage.css'; // Import CSS for AboutPage styling
import logo from "./resize.jpg"
import logo1 from "./logo1.jpg"
import Footer from './Footer';
import tes from "./team.png"
import { Divider } from '@mui/material';
const AboutPage = () => {
  const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
  setWidth(window.innerWidth);
}
useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
  }
}, []);

const isMobile = width <= 768;
console.log(isMobile)
  return (
    <div className='back'>
      <div className='center'>
      <img src={tes} className='im'></img> <h2>Anthony Tremonte</h2>
      <Divider/>
    <div className="about-container">
    
      <div className="about-content">
         <div className='firstsection'>
            
       <h3>Owner<br/>Head Instructor</h3>

        <img src={logo} alt="Anthony Tremonte" /> 
         </div>
      <div className='secondsection'>
         <p>
         Meet Anthony Tremonte, a Niagara
native and a seasoned goalie coach
with extensive experience training
athletes of all ages. Anthony is
entering his 4th season as the goalie
coach for the Niagara Falls Canucks
of the OJHL. In the 2023-2024
season, Anthony joined the Chinese
National Hockey Program as Head
Goalie Coach for the Senior and U18
Women’s Teams. Known for his
expertise and dedication, Anthony is
trusted by players and coaches alike
to deliver high-quality training.
        </p>
      </div>

      </div>
    
    </div>
    <Divider/>
    <h2>Joseph Murdaca</h2>
    <Divider/>
    {isMobile
        ? 
        <div className="about-container">
    
    <div className="about-content">
       <div className='firstsection'>
          
     <h3>Head Instructor</h3>

      <img src={logo1} alt="Anthony Tremonte" /> 
       </div>
    <div className='secondsection'>
       <p>
       Meet Joseph Murdaca, a Niagara
native and current professional
goaltender. After his OHL career,
Joseph has played over 50 games
between the AHL and ECHL. With
a plethora of knowledge and
playing experience, Joesph aims
to develop the future generation of
goaltenders.
      </p>
    </div>

    </div>
  
  </div>

        : 
        <div className="about-container">
    
    <div className="about-content">
       
    <div className='secondsection'>
       <p>
       Meet Joseph Murdaca, a Niagara
native and current professional
goaltender. After his OHL career,
Joseph has played over 50 games
between the AHL and ECHL. With
a plethora of knowledge and
playing experience, Joesph aims
to develop the future generation of
goaltenders.
      </p>
    </div>
<div className='firstsection'>
          
     <h3>Head Instructor</h3>

      <img src={logo1} alt="Anthony Tremonte" /> 
       </div>
    </div>
  
  </div>


      }

    
    </div>
    <Footer/>
    </div>
  );
};

export default AboutPage;

