// App.js
// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import './styles.css'; // Import CSS styles
import "./Demonized.ttf"

// Import the image
import logo from './logo.png';
import Footer from './Footer';
import ProductPage from './ProductPage';
import NavbarIcon from './NavIcon';
import Testimonials from './Testimonials';

const App = () => {
  const [first, setfirst] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth>600){
        setfirst(true)
      }else{
        setfirst(false)
      }
      
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <Router>
      <div>
        {/* Header */}
        <header className="header">
          
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          {first ?
  <nav className="nav-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">Our Coaches</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/">Testimonials</Link>
              </li>
            </ul>
          </nav>
         :
          <NavbarIcon/>
      }
        
        </header>

        {/* Routes */}
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path='/testimonials' element={<Testimonials/>}/>
        </Routes>
       
      </div>
    </Router>
  );
};

export default App;
