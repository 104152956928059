import React, { useState } from 'react';

import './ContactForm.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email.js configuration
    const serviceId = 'service_etameri';
    const templateId = 'template_aleoz9n';
    const userId = 'user_lLFT2bozQpLY6L2vt3yhx';

    const templateParams = {
      user_name: name,
      user_email: email,
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, userId)
      .then(() => {
        setSent(true);
      })
      .catch((error) => console.error('Email Error:', error));
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      {sent ? (
        <p>Thank you for contacting us! We'll get back to you soon.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
