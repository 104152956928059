// HomePage.js
// HomePage.js
import React, { useState } from 'react';
import './HomePage.css'; // Import CSS for HomePage styling
import Footer from './Footer';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';
import rob from "./robby.jpg"
import cast from "./cats.jpg"
import p4 from "./p3.jpg"
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import rink1 from "./rink1.png"
import test from "./test.jpeg"
import Divider from '@mui/material/Divider';
import Carousel from './Carousel';
import { InstagramEmbed } from 'react-social-media-embed';
import "./Demonized.ttf"

const HomePage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

// Media query for detecting mobile screen
const isMobile = useMediaQuery({ maxWidth: 768 });

// Function to toggle dropdown
const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
  return (
    <div className='backg'>
  
    <div className='homepage'>
    <img className='headimg' src={rink1}></img>
    <div style={{ margin:"auto"}}>


    
    {/* <div className="hero" >
      
        <div className="hero-content">
          <h1>Elevate your game with Tremonte Goaltending</h1>
  
          <a href="./products" className="button">Our Products</a>
        </div>
        <div>
    
        </div>
      </div> */}
     <div className="container">
      <div className="row">
       
        <div className="column text-column">
        <h2>Unlock Your Full Potential</h2>
          <ul>
          <li>Personalized Coaching: Offering private and semi-private sessions tailored to individual needs.</li>
<li>Universal Appeal: Ideal for goalies of all ages and skill levels, from beginners to seasoned pros.</li>
<li>Seasoned Instructors: Benefit from highly experienced coaches with deep knowledge of the game.</li>
<li>Proven Success: A track record of success demonstrated through the achievements of our goalies.</li>
<li>Game-Changing Results: Witness significant improvements in your game, guaranteed.</li>

          </ul>
        </div> 
        <div className="column">
          <img src={cast} alt="Image" className="image" />
        </div>
      </div>
    </div>
      <Divider/>
       <section className="cta">
      
      <h2 style={{textAlign:"center", fontFamily:"Demonized"}}>Are you ready to seperate yourself from the pack?</h2>
      <p>Click below and start to see the Tremonte Goaltending difference for yourself</p>
      <div className="buf">
<Link className='butp' to=
        "/products">Our Products</Link>
      </div>
      <Divider/>
      <div className='ttt'> 
      <div className='instagram-feed'>
   
    <InstagramEmbed url="https://www.instagram.com/p/C51T-SuOj54/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="  />
</div>
             <ContactForm/>
       </div>
         
      
    </section>
    </div>
    </div>
    
    <Footer/>
      </div>
  );
};

export default HomePage;
